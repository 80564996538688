import React, { Component } from 'react';
import landerRightBG from '../static/landerRightImg.svg';
import landerLeftLogo from '../static/landerLeftLogo.png';

const withApiCallRedirect = (WrappedComponent) => {
  class ApiCallRedirectComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        authenticationError: false,
      };
    }
    
    handleFormSubmit = (event) => {
      event.preventDefault();

      const username = event.target.elements.username.value;
      const password = event.target.elements.password.value;

      const userCredentials = [
        { username: 'mohit', password: 'mohit' },
        { username: 'myaimate', password: 'myaimate' },
        { username: "pocapsahmednagar", password: "apsnavyug100" },
        { username: "sairaj001", password: "apsnavyug101" },
        { username: "shubham002", password: "apsnavyug102" },
        { username: "aman003", password: "apsnavyug103" },
        { username: "pocapsbarrackpore", password: "apsnavyug104" },
        { username: "sutirtho004", password: "apsnavyug105" },
        { username: "trisha005", password: "apsnavyug106" },
        { username: "adhyashis006", password: "apsnavyug107" },
        { username: "pocapsbdbari", password: "apsnavyug108" },
        { username: "maninder007", password: "apsnavyug109" },
        { username: "harsh008", password: "apsnavyug110" },
        { username: "kashish009", password: "apsnavyug111" },
        { username: "pocapsbolarum", password: "apsnavyug112" },
        { username: "rajaeshwar010", password: "apsnavyug113" },
        { username: "vedang011", password: "apsnavyug114" },
        { username: "arayma012", password: "apsnavyug115" },
        { username: "pocapscannanore", password: "apsnavyug116" },
        { username: "yash013", password: "apsnavyug117" },
        { username: "adam014", password: "apsnavyug118" },
        { username: "alan015", password: "apsnavyug119" },
        { username: "pocapskanpur", password: "apsnavyug120" },
        { username: "arpit016", password: "apsnavyug121" },
        { username: "abhimanyu017", password: "apsnavyug122" },
        { username: "avinash018", password: "apsnavyug123" },
        { username: "pocapslbs", password: "apsnavyug124" },
        { username: "vinamra019", password: "apsnavyug125" },
        { username: "deepan020", password: "apsnavyug126" },
        { username: "aatreya021", password: "apsnavyug127" },
        { username: "pocapsmadhopur", password: "apsnavyug128" },
        { username: "likhith022", password: "apsnavyug129" },
        { username: "gagandeep023", password: "apsnavyug130" },
        { username: "kanya024", password: "apsnavyug131" },
        { username: "posapssriganganagar", password: "apsnavyug132" },
        { username: "sohail025", password: "apsnavyug133" },
        { username: "rohan026", password: "apsnavyug134" },
        { username: "tanisha027", password: "apsnavyug135" },
        { username: "pocapsalwar", password: "apsnavyug136" },
        { username: "abijot028", password: "apsnavyug137" },
        { username: "tanish029", password: "apsnavyug138" },
        { username: "aarishi030", password: "apsnavyug139" },
        { username: "pocapsbabina", password: "apsnavyug140" },
        { username: "nifaj031", password: "apsnavyug141" },
        { username: "deepika032", password: "apsnavyug142" },
        { username: "yash033", password: "apsnavyug143" },
        { username: "pocapsahmedabad", password: "apsnavyug144" },
        { username: "adarsh034", password: "apsnavyug146" },
        { username: "aasim035", password: "apsnavyug147" },
        { username: "rhythm036", password: "apsnavyug148" },
        { username: "pocapsamritsar", password: "apsnavyug149" },
        { username: "lalith037", password: "apsnavyug150" },
        { username: "aakarsh038", password: "apsnavyug151" },
        { username: "smit039", password: "apsnavyug152" },
        { username: "pocapsbirpur", password: "apsnavyug153" },
        { username: "garvita040", password: "apsnavyug154" },
        { username: "aditya041", password: "apsnavyug155" },
        { username: "pocapshappyvalley", password: "apsnavyug156" },
        { username: "tanishka042", password: "apsnavyug157" },
        { username: "anuraag043", password: "apsnavyug158" },
        { username: "arav044", password: "apsnavyug159" },
        { username: "pocapskota", password: "apsnavyug160" },
        { username: "arnav045", password: "apsnavyug161" },
        { username: "divyanshu046", password: "apsnavyug162" },
        { username: "himanshu047", password: "apsnavyug163" },
        { username: "pocapsmhow", password: "apsnavyug164" },
        { username: "spandan048", password: "apsnavyug165" },
        { username: "atharv049", password: "apsnavyug166" },
        { username: "arpit050", password: "apsnavyug167" },
        { username: "pocapsmiransahib", password: "apsnavyug168" },
        { username: "arush051", password: "apsnavyug169" },
        { username: "parshant052", password: "apsnavyug170" },
        { username: "lochan053", password: "apsnavyug171" },
        { username: "pocapsratnuchak", password: "apsnavyug172" },
        { username: "aayushman054", password: "apsnavyug173" },
        { username: "shreya055", password: "apsnavyug174" },
        { username: "pakhi056", password: "apsnavyug175" },
        { username: "pocapsspm", password: "apsnavyug176" },
        { username: "divyansh057", password: "apsnavyug177" },
        { username: "saksham058", password: "apsnavyug178" },
        { username: "krish059", password: "apsnavyug179" },
        { username: "pocapsclementtown", password: "apsnavyug180" },
        { username: "harshit060", password: "apsnavyug181" },
        { username: "devansh061", password: "apsnavyug182" },
        { username: "kapish062", password: "apsnavyug183" },
        { username: "pocapschennai", password: "apsnavyug184" },
        { username: "jayatra063", password: "apsnavyug185" },
        { username: "harshini064", password: "apsnavyug186" },
        { username: "yash065", password: "apsnavyug187" },
        { username: "pocapsbasistha", password: "apsnavyug188" },
        { username: "radhmita066", password: "apsnavyug189" },
        { username: "prity067", password: "apsnavyug190" },
        { username: "lucilla068", password: "apsnavyug191" },
        { username: "pocapsdk", password: "apsnavyug192" },
        { username: "aaryan069", password: "apsnavyug193" },
        { username: "mayank070", password: "apsnavyug194" },
        { username: "arjun071", password: "apsnavyug195" },
        { username: "pocapsjodhpur", password: "apsnavyug196" },
        { username: "vidhit072", password: "apsnavyug197" },
        { username: "shashank073", password: "apsnavyug198" },
        { username: "hiten074", password: "apsnavyug199" },
        { username: "pocapskamaraj", password: "apsnavyug200" },
        { username: "praveen075", password: "apsnavyug201" },
        { username: "sumit076", password: "apsnavyug202" },
        { username: "gajodhar077", password: "apsnavyug203" },
        { username: "pocapsnagrota", password: "apsnavyug204" },
        { username: "vihaan078", password: "apsnavyug205" },
        { username: "ekjot079", password: "apsnavyug206" },
        { username: "meenakshi080", password: "apsnavyug207" },
        { username: "pocapsnarangi", password: "apsnavyug208" },
        { username: "karunesh081", password: "apsnavyug209" },
        { username: "satvik082", password: "apsnavyug210" },
        { username: "ayush083", password: "apsnavyug211" },
        { username: "pocapsocp", password: "apsnavyug212" },
        { username: "kshitiz084", password: "apsnavyug213" },
        { username: "anshika085", password: "apsnavyug214" },
        { username: "shiksha086", password: "apsnavyug215" },
        { username: "pocapspune", password: "apsnavyug216" },
        { username: "veer087", password: "apsnavyug217" },
        { username: "aarav088", password: "apsnavyug218" },
        { username: "abeer089", password: "apsnavyug219" },
        { username: "pocapsrkp", password: "apsnavyug220" },
        { username: "thota090", password: "apsnavyug221" },
        { username: "prakhyat091", password: "apsnavyug222" },
        { username: "harshit092", password: "apsnavyug223" },
        { username: "pocapsdharroad", password: "apsnavyug224" },
        { username: "bramhtej093", password: "apsnavyug225" },
        { username: "breena094", password: "apsnavyug226" },
        { username: "madhav095", password: "apsnavyug227" },
        { username: "pocapskaluchak", password: "apsnavyug228" },
        { username: "tejasvir096", password: "apsnavyug229" },
        { username: "hartej097", password: "apsnavyug230" },
        { username: "harshveer098", password: "apsnavyug231" },
        { username: "pocapsbathinda", password: "apsnavyug232" },
        { username: "nikhil099", password: "apsnavyug233" },
        { username: "ashwin100", password: "apsnavyug234" },
        { username: "pratik101", password: "apsnavyug235" },
        { username: "pocapsdevlali", password: "apsnavyug236" },
        { username: "harsh102", password: "apsnavyug237" },
        { username: "priyesh103", password: "apsnavyug238" },
        { username: "adarsh104", password: "apsnavyug239" },
        { username: "pocapssv", password: "apsnavyug240" },
        { username: "aarush105", password: "apsnavyug241" },
        { username: "akshaj106", password: "apsnavyug242" },
        { username: "pocapsjammu", password: "apsnavyug243" },
        { username: "aryan107", password: "apsnavyug244" },
        { username: "shardul108", password: "apsnavyug245" },
        { username: "kiranjot109", password: "apsnavyug246" },
        { username: "pocapsjanglot", password: "apsnavyug247" },
        { username: "vivaan110", password: "apsnavyug248" },
        { username: "aarush111", password: "apsnavyug249" },
        { username: "bhagyajeet112", password: "apsnavyug250" },
        { username: "pocapsmeerut", password: "apsnavyug251" },
        { username: "darshit113", password: "apsnavyug252" },
        { username: "ravi114", password: "apsnavyug253" },
        { username: "aarav115", password: "apsnavyug254" },
        { username: "pocapsmumbai", password: "apsnavyug255" },
        { username: "kovid116", password: "apsnavyug256" },
        { username: "gitanshu117", password: "apsnavyug257" },
        { username: "aarush118", password: "apsnavyug258" },
        { username: "pocapsnoida", password: "apsnavyug259" },
        { username: "bhavya119", password: "apsnavyug260" },
        { username: "jari120", password: "apsnavyug261" },
        { username: "akshansh121", password: "apsnavyug262" },
        { username: "pocaapsnpc", password: "apsnavyug263" },
        { username: "abhimanyu122", password: "apsnavyug264" },
        { username: "vaishali123", password: "apsnavyug265" },
        { username: "shiksha124", password: "apsnavyug266" },
        { username: "pocapsraiwala", password: "apsnavyug267" },
        { username: "rishant125", password: "apsnavyug268" },
        { username: "sarang126", password: "apsnavyug269" },
        { username: "abhijeet127", password: "apsnavyug270" },
        { username: "pocapsranikhet", password: "apsnavyug271" },
        { username: "harshit128", password: "apsnavyug272" },
        { username: "debabrata129", password: "apsnavyug273" },
        { username: "deepanshu130", password: "apsnavyug274" }
      ];
      

      const matchedUser = userCredentials.find(
        (user) => user.username === username && user.password === password
      );

      if (matchedUser) {
        // Authentication successful
        sessionStorage.setItem('authenticated', 'true');
        window.location.href = '/';
      } else {
        // Authentication failed
        this.setState({ authenticationError: true }); // Set the error state
      }
    

      // // Make the API call to authenticate the user
      // fetch('https://myaimate-backend.onrender.com/api/v1/auth/login', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({ username, password }),
      // })
      //   .then((response) => response.json())
      //   .then((data) => {
          
      //     if (data.status === 200) {
      //       sessionStorage.setItem('loginToken', JSON.stringify(data.data.token));
      //       sessionStorage.setItem('authenticated', 'true');
      //       // If the API response is 'allowed', trigger a programmatic redirection
      //       window.location.href = '/'; // Replace with the actual path
      //     }
      //     else {
      //       this.setState({ authenticationError: true });
      //     }
      //   })
      //   .catch((error) => {
      //     console.error('API call failed', error);
      //     this.setState({ authenticationError: true });
      //   });
    };

    componentDidMount() {
      // Check if the stored API response exists
      const storedApiResponse = JSON.parse(sessionStorage.getItem('authenticated'));
  
      if (storedApiResponse) {
        // If it exists, set a timeout to clear it after 30 seconds
        setTimeout(() => {
          sessionStorage.removeItem('authenticated');
        }, 10800000); // 3hours in milliseconds
      }
    }

    render() {
      const { authenticationError } = this.state;
      // Get the stored API response from session storage
      // const storedApiResponse = JSON.parse(sessionStorage.getItem('apiResponse'));
      const storedApiResponse = sessionStorage.getItem("authenticated");
      if (!storedApiResponse) {
        // If the stored API response is empty, render the login form
        return (
          <div style={{ position: 'absolute', display: 'flex', width: '100%', marginTop: 'auto' }}>
            <div style={{ textAlign: 'center', width: '55vw', margin: 'auto', padding: '0 5%' }}>
              <img style={{ maxWidth: '45%' }} src={landerLeftLogo} alt="Aimate Logo" />
              <h1 style={{ fontSize: '3em' }}>Login into your Account</h1>
              <form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }} onSubmit={this.handleFormSubmit}>
                <input style={{
                  background: '#eee', width: '60%',
                  borderRadius: '10px',
                  fontSize: '1.2em',
                  margin: '10px 0',
                  padding: '1em 1.5em',
                  border: 'none',
                  outline: 'none'
                }} type="text" name="username" placeholder="Username" />
                <input style={{
                  background: '#eee', width: '60%',
                  borderRadius: '10px',
                  fontSize: '1.2em',
                  margin: '10px 0',
                  padding: '1em 1.5em',
                  border: 'none',
                  outline: 'none'
                }} type="password" name="password" placeholder="Password" />
                <button style={{
                  background: '#eee', width: '60%',
                  borderRadius: '10px',
                  fontSize: '1.2em',
                  margin: '10px 0',
                  padding: '1em 1.5em',
                  border: 'none',
                  outline: 'none',
                  background: '#49a4ff',
                  color: 'white',
                  fontWeight: 600,
                  fontSize: '16px',
                  cursor: 'pointer',
                }} type="submit">Submit</button>
              </form>
              {authenticationError && <p style={{ color: 'red' }}>Authentication failed. Please check your username and password.</p>}
            </div>
            <div style={{
              margin: 'auto',
              width: '45vw'
            }}>
              <img style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }} src={landerRightBG} alt="Aimate Logo" />
            </div>
          </div>
        );
      } else {
        // Render the wrapped component
        return <WrappedComponent {...this.props} />;
      }
    }
  }

  return ApiCallRedirectComponent;
};

export default withApiCallRedirect;
